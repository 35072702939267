<template>
  <slot name="left"></slot>
  <div :class="`page-header ${extraClass}`">
    <div class="page-title">
      <h4>{{ title }}</h4>
      <h6>{{ title1 }}</h6>
    </div>
    <slot name="right"></slot>

    <div class="page-btn" v-for="(item, index) in items" :key="index">
      <router-link
        v-bind="
          typeof item.attributes == 'function'
            ? item.attributes()
            : item.attributes
        "
        @click="item.onclick"
        :to="item.addname"
        class="btn btn-added"
        ><img src="@/assets/img/icons/plus.svg" class="me-2" alt="img" />{{
          item.text
        }}</router-link
      >
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    "extra-class": {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    title1: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
