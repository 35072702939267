<template>
  <div>
    <div
      class="modal fade"
      id="addsplashscreen"
      data-bs-focus="false"
      tabindex="-1"
      aria-labelledby="addsplashscreen"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add A New Splash Screen</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Title / Tag</label>
                  <div class="input-groupicon">
                    <input
                      class="form-control"
                      v-model="splashScreenUpdate.title"
                    />
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>End Date</label>
                  <RangePicker
                    style="padding: 7px"
                    class="col-12 form-control d-flex"
                    :value="[
                      dayJs(splashScreenUpdate.start_date),
                      dayJs(splashScreenUpdate.end_date),
                    ]"
                    @calendar-change="
                      ($event) => {
                        if (!$event) {
                          return;
                        }
                        splashScreenUpdate.start_date = $event[0];
                        splashScreenUpdate.end_date = $event[1];
                      }
                    "
                  />
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group mb-0">
                  <label>Image</label>
                  <div class="image-upload">
                    <input
                      required
                      multiple
                      name="image"
                      accept="image/*"
                      @input="pickFile($event.target)"
                      type="file"
                    />
                    <div class="image-uploads">
                      <img
                        src="../../../../assets/img/icons/upload.svg"
                        alt="img"
                      />
                      <h4>Drag and drop a file to upload</h4>
                    </div>
                  </div>
                  <span class="text-danger" v-if="errors.video">
                    {{ errors.video }}
                  </span>
                </div>
                <img
                  style="width: 100%; height: 300px; object-fit: cover"
                  v-if="splashScreenUpdate.image"
                  :src="getFileUrl(splashScreenUpdate.image)"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="submit"
              type="button"
              @click="submitForm"
              class="btn btn-submit"
            >
              Submit
            </button>
            <button
              type="button"
              class="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayJs from "dayjs";
import { getFileUrl } from "@/helpers";
import { RangePicker } from "ant-design-vue";
import { mapActions, mapMutations } from "vuex";
export default {
  props: {
    selectedSplashScreen: Object,
  },
  emits: {
    splashScreenAdded: () => {},
  },
  components: { RangePicker },
  data() {
    return {
      dayJs,
      getFileUrl,
      access_code: null,
      errors: {},
      splashScreenUpdate: {
        title: null,
        start_date: null,
        end_date: null,
        image: null,
      },
      isUpdating: false,
    };
  },
  methods: {
    ...mapActions({
      _addUser: "users/add",
      _addSplashScreen: "mobileAds/addSplashScreen",
      _updateSplashScreen: "mobileAds/updateSplashScreen",
    }),
    ...mapMutations({
      _notify: "notify",
    }),
    submitForm() {
      if (this.isUpdating) {
        //update
        this._updateSplashScreen({
          payload: {
            ...this.splashScreenUpdate,
          },
          id: this.splashScreenUpdate.id,
        }).then((res) => {
          this.splashScreenUpdate = {};

          $(".modal").modal("hide");
          this.$emit("splashScreenAdded");
          this._notify({
            title: "Splash Screen Added",
            message: "New Splash Screen Successfully Uploaded",
          });
        });
      } else {
        this._addSplashScreen({
          payload: {
            ...this.splashScreenUpdate,
          },
        }).then((res) => {
          this.splashScreenUpdate = {};

          $(".modal").modal("hide");
          this.$emit("splashScreenAdded");
          this._notify({
            title: "Splash Screen Added",
            message: "New Splash Screen Successfully Uploaded",
          });
        });
      }
    },
    pickFile(event) {
      let file = event.files[0];
      this.splashScreenUpdate.image = file;
    },
  },
  watch: {
    selectedSplashScreen: {
      handler(newSelectedSplashScreen) {
        this.splashScreenUpdate = {
          ...newSelectedSplashScreen,
        };
        this.isUpdating = !!newSelectedSplashScreen;
      },
      immediate: true,
    },
  },
  mounted() {},
};
</script>
<style>
.video-upload-preview {
  border-radius: 6px;
  overflow: hidden;
  display: block;
  height: 300px;
}

.video-upload-preview video {
  height: 100%;
  width: 100%;
}
</style>
