import { api } from "@/config";

export const mobileAds = {
  namespaced: true,
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/adverts/mobile${path}`;
  },
  actions: {
    getSplashVideos() {
      return api.get(mobileAds.url("splash"));
    },
    addSplashVideo({ context }, { payload }) {
      return api.post(mobileAds.url("splash"), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    deleteSplashVideo({ context }, { id }) {
      return api.delete(mobileAds.url(`splash/${id}`));
    },
    updateSplashVideos({ context }, { id, payload }) {
      return api.post(
        mobileAds.url(`splash/${id}`),
        {
          ...payload,
          _method: "PATCH",
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    // splash screen
    getSplashScreens() {
      return api.get(mobileAds.url("splash-screen"));
    },
    addSplashScreen({ context }, { payload }) {
      return api.post(mobileAds.url("splash-screen"), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    deleteSplashScreen({ context }, { id }) {
      return api.delete(mobileAds.url(`splash-screen/${id}`));
    },
    updateSplashScreen({ context }, { id, payload }) {
      return api.post(
        mobileAds.url(`splash-screen/${id}`),
        {
          ...payload,
          _method: "PATCH",
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  },
};
