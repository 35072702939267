export default Object.freeze({
  colorPrimary: "#ff9f43", // Primary Color (Orange)

  // Computed colors based on primary color
  colorSuccess: "#52c41a", // Green (Standard Success Color)
  colorWarning: "#faad14", // Yellow (Standard Warning Color)
  colorError: "#ff4d4f", // Red (Standard Error Color)
  colorInfo: "#1677ff", // Blue (Standard Info Color)

  // Text and Background Colors
  colorTextBase: "#333333", // Dark Gray for text
  colorBgBase: "#f7f7f7", // Light Gray for background
});
