<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <div class="d-flex gap-2">
          <f-table
            extra-class="col-12"
            :filters="['tag', 'target_users']"
            :data="splashScreens"
            :columns="columns"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'action'">
                <div class="d-flex gap-2">
                  <span
                    class="badges"
                    style="
                      cursor: pointer;
                      border: 1px solid #28c76f;
                      color: #28c76f;
                    "
                    @click="openModal(record)"
                    >Edit</span
                  >
                  <a
                    class="confirm-text"
                    href="javascript:void(0);"
                    @click="deleteSplashScreen(record.id)"
                  >
                    <img
                      src="../../../../assets/img/icons/delete.svg"
                      alt="img"
                    />
                  </a>
                </div>
              </template>
              <template v-else-if="column.key === 'image'">
                <div
                  class="productimgname p-1"
                  style="
                    height: 50px;
                    width: 50px;
                    border-radius: 6px;
                    overflow: hidden;
                  "
                >
                  <a-image alt="image" :src="record.image" />
                </div>
              </template>
              <template v-else-if="column.key === 'date'">
                <span>{{
                  formatTimeStamp(record[column.dataIndex], false)
                }}</span>
              </template>
            </template>
          </f-table>
        </div>
      </div>
    </div>

    <Addsplashscreen
      :selectedSplashScreen="selectedSplashScreen"
      @splashScreenAdded="fetchSplashScreens"
    ></Addsplashscreen>
  </div>
</template>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Image",
    key: "image",
    sorter: {
      compare: (a, b) => {
        a = a.tag;
        b = b.tag;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Title",
    key: "title",
    dataIndex: "title",
    sorter: {
      compare: (a, b) => {
        a = a.title;
        b = b.title;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status;
        b = b.status;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "date",
    sorter: {
      compare: (a, b) => {
        a = a.start_date;
        b = b.start_date;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "date",
    sorter: {
      compare: (a, b) => {
        a = a.end_date;
        b = b.end_date;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import { mapActions, mapGetters, mapMutations } from "vuex";
import Addsplashscreen from "./addsplashscreen.vue";
import { formatTimeStamp } from "@/helpers";
export default {
  components: { Addsplashscreen },
  data() {
    return {
      formatTimeStamp,
      filter: false,
      title: "Splash Screens",
      title1: "Mobile Splash Screen",
      items: [
        {
          text: "Add Splash Screen",
          addname: "#",
          attributes: () => {
            const vue = this;
            return {
              onClick() {
                vue.openModal();
              },
            };
          },
        },
      ],
      selectedImageUrl: null,
      splashScreens: [],
      selectedSplashScreen: null,
      columns,
    };
  },
  methods: {
    ...mapGetters(["formatAmount"]),
    ...mapMutations({
      _notify: "notify",
    }),
    ...mapActions({
      _fetchSplashScreens: "mobileAds/getSplashScreens",
      _deleteSplashScreen: "mobileAds/deleteSplashScreen",
    }),
    deleteSplashScreen(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteSplashScreen({ id: id }).then((res) => {
            this._notify({
              title: "Video Deleted",
            });
            this.fetchSplashScreens();
          });
        }
      });
    },
    previewImage(url) {
      this.selectedImageUrl = url;
    },
    openModal(splashScreen = null) {
      this.selectedSplashScreen = splashScreen;
      $("#addsplashscreen").modal("show");
    },
    fetchSplashScreens() {
      this._fetchSplashScreens().then((response) => {
        this.splashScreens = response.data.data.map((splashScreen) => {
          return {
            ...splashScreen,
          };
        });
      });
    },
  },
  mounted() {
    this.fetchSplashScreens();
  },
  name: "splashvideo",
};
</script>
