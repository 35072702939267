<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <div class="d-flex gap-2">
          <f-table
            extra-class="col-md-8"
            :filters="['tag', 'target_users']"
            :data="videos"
            :columns="columns"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'action'">
                <div class="d-flex gap-2">
                  <span
                    class="badges"
                    style="
                      cursor: pointer;
                      border: 1px solid #28c76f;
                      color: #28c76f;
                    "
                    @click="previewVideo(record.video.url)"
                    >Preview</span
                  >
                  <a
                    class="confirm-text"
                    href="javascript:void(0);"
                    @click="deleteVideo(record.id)"
                  >
                    <img
                      src="../../../../assets/img/icons/delete.svg"
                      alt="img"
                    />
                  </a>
                </div>
              </template>
              <template v-else-if="column.key === 'video'">
                <div class="productimgname">
                  <a href="javascript:void(0);" class="product-img"> </a>
                  <a href="javascript:void(0);">{{ record.video.tag }}</a>
                </div>
              </template>
            </template>
          </f-table>

          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="video-preview">
                  <video id="video" controls>
                    <source :src="selectedVideoUrl" type="" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Splashvideomodal @onVideoAdded="getSplashVideos"></Splashvideomodal>
  </div>
</template>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Tag",
    dataIndex: "tag",
    sorter: {
      compare: (a, b) => {
        a = a.tag;
        b = b.tag;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Target Users",
    key: "target_users",
    dataIndex: "target_users",
    sorter: {
      compare: (a, b) => {
        a = a.target_users;
        b = b.target_users;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Watch",
    dataIndex: "total_watch",
    sorter: {
      compare: (a, b) => {
        a = a.total_watch;
        b = b.total_watch;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import { mapActions, mapGetters, mapMutations } from "vuex";
import Splashvideomodal from "./splashvideomodal.vue";
export default {
  components: { Splashvideomodal },
  data() {
    return {
      filter: false,
      title: "Splash Screen Video",
      title1: "Mobile Splash Screen Video",
      items: [
        {
          text: "Add Video",
          addname: "#",
          attributes: {
            "data-bs-toggle": "modal",
            "data-bs-target": "#addsplashvideo",
          },
        },
      ],
      selectedVideoUrl: null,
      videos: [],
      columns,
    };
  },
  methods: {
    ...mapGetters(["formatAmount"]),
    ...mapMutations({
      _notify: "notify",
    }),
    ...mapActions({
      _getSplashVideos: "mobileAds/getSplashVideos",
      _deleteSplashVideo: "mobileAds/deleteSplashVideo",
    }),
    deleteVideo(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteSplashVideo({ id: id }).then((res) => {
            this._notify({
              title: "Video Deleted",
            });
            this.getSplashVideos();
          });
        }
      });
    },
    previewVideo(url) {
      let element = document.getElementById("video");
      this.selectedVideoUrl = url;
      element.load();
    },
    getSplashVideos() {
      this._getSplashVideos().then((response) => {
        this.videos = response.data.data.map((video) => {
          return {
            id: video.id,
            tag: video.tag,
            video: {
              url: video.url,
            },
            target_users: video.account_type.name,
            account_type: video.account_type,
            total_watch: video.total_watch,
          };
        });
      });
    },
  },
  mounted() {
    this.getSplashVideos();
  },
  name: "splashvideo",
};
</script>
