import { createRouter, createWebHistory } from "vue-router";

import store from "@/store";
import { isAuth } from "@/helpers";

import Activities from "@/views/pages/activite/activities.vue";
import Addbrand from "@/views/pages/product/brand/addbrand.vue";
import Addcategory from "@/views/pages/product/category/addcategory.vue";
import Addproduct from "@/views/pages/product/addproduct.vue";
import Brandlist from "@/views/pages/product/brand/brandlist.vue";
import Categorylist from "@/views/pages/product/category/categorylist.vue";
import Editbrand from "@/views/pages/product/brand/editbrand.vue";
import Editcategory from "@/views/pages/product/category/editcategory.vue";
import Editproduct from "@/views/pages/product/editproduct.vue";
import Editsubcategory from "@/views/pages/product/category/subcat/editsubcategory.vue";
import ProductDetails from "@/views/pages/product/product-details.vue";
import Productlist from "@/views/pages/product/productlist.vue";
import OrderList from "@/views/pages/sales/orderlist.vue";
import OrderDetail from "@/views/pages/sales/order-details.vue";
import Subaddcategory from "@/views/pages/product/category/subcat/addsubcategory.vue";
import Subcategorylist from "@/views/pages/product/category/subcat/subcategorylist.vue";
import Addsale from "@/views/pages/sales/add-sales.vue";
import ConfirmSale from "@/views/pages/sales/confirm-sales.vue";
import WareHouseSale from "@/views/pages/sales/warehouse-sales.vue";
import GateSale from "@/views/pages/sales/gate-sales.vue";
import DispatchedSale from "@/views/pages/sales/dispatched-sales.vue";
import CompletedSale from "@/views/pages/sales/completed-sales.vue";
import Addorder from "@/views/pages/sales/add-order.vue";
import createsalesreturns from "@/views/pages/sales/createsalesreturns.vue";
import Editsales from "@/views/pages/sales/edit-sales.vue";
import Editsalesreturns from "@/views/pages/sales/editsalesreturns.vue";
import Saleslist from "@/views/pages/sales/saleslist.vue";
import Addpurchase from "@/views/pages/purchase/addpurchase.vue";
import Editpurchase from "@/views/pages/purchase/editpurchase.vue";
import Importpurchase from "@/views/pages/purchase/importpurchase.vue";
import Purchaselist from "@/views/pages/purchase/purchaselist.vue";
import Index from "@/views/pages/dashboard/index.vue";
import IndexOne from "@/views/pages/dashboard/index-one.vue";
import IndexTwo from "@/views/pages/dashboard/index-two.vue";
import IndexThree from "@/views/pages/dashboard/index-three.vue";
import IndexFour from "@/views/pages/dashboard/index-four.vue";
import createexpense from "@/views/pages/expense/createexpense.vue";
import Editexpense from "@/views/pages/expense/editexpense.vue";
import Expensecategory from "@/views/pages/expense/expensecategory.vue";
import Expenselist from "@/views/pages/expense/expenselist.vue";
import Addquotation from "@/views/pages/quotation/addquotation.vue";
import Editquotation from "@/views/pages/quotation/editquotation.vue";
import Quotationlist from "@/views/pages/quotation/quotationlist.vue";
import Addtransfer from "@/views/pages/transfer/addtransfer.vue";
import Edittransfer from "@/views/pages/transfer/edittransfer.vue";
import Importtransfer from "@/views/pages/transfer/importtransfer.vue";
import transferlist from "@/views/pages/transfer/transferlist.vue";
import createpurchasereturn from "@/views/pages/return/createpurchasereturn.vue";
import createsalesreturn from "@/views/pages/return/createsalesreturn.vue";
import Editpurchasereturn from "@/views/pages/return/editpurchasereturn.vue";
import Editsalesreturn from "@/views/pages/return/editsalesreturn.vue";
import Purchasereturnlist from "@/views/pages/return/purchasereturnlist.vue";
import Salesreturnlist from "@/views/pages/return/salesreturnlist.vue";
import Salesreturnlists from "@/views/pages/return/salesreturnlist.vue";
import Addcustomer from "@/views/pages/people/addcustomer.vue";
import Addstore from "@/views/pages/people/addstore.vue";
import Addsupplier from "@/views/pages/people/addsupplier.vue";
import Adduser from "@/views/pages/people/adduser.vue";
import Editcustomer from "@/views/pages/people/editcustomer.vue";
import Editstore from "@/views/pages/people/editstore.vue";
import Editsupplier from "@/views/pages/people/editsupplier.vue";
import Edituser from "@/views/pages/users/newuseredit.vue";
import Supplierlist from "@/views/pages/people/supplierlist.vue";
import userlist from "@/views/pages/people/userlist.vue";
import customerlist from "@/views/pages/people/customer/customerlist.vue";
import Editcountry from "@/views/pages/places/editcountry.vue";
import Editstate from "@/views/pages/places/editstate.vue";
import Newcountry from "@/views/pages/places/newcountry.vue";
import Newstate from "@/views/pages/places/newstate.vue";
import Statelist from "@/views/pages/places/statelist.vue";
import Storelist from "@/views/pages/places/storelist.vue";
import Countrieslist from "@/views/pages/places/countrylist/countrieslist.vue";
import Blankpage from "@/views/pages/blankpage.vue";
import Profile from "@/views/pages/profile.vue";
import Component from "@/views/pages/components/components.vue";
import Error404 from "@/views/pages/error/error-404.vue";
import Error500 from "@/views/pages/error/error-500.vue";
import clipboard from "@/views/pages/elements/clipboard.vue";
import counter from "@/views/pages/elements/counter.vue";
import Dragdrop from "@/views/pages/elements/drag-drop.vue";
import Lightbox from "@/views/pages/elements/lightbox.vue";
import notification from "@/views/pages/elements/notification.vue";
import Barcode from "@/views/pages/product/barcodeproduct/barcode.vue";
import Popover from "@/views/pages/elements/popover.vue";
import Rangeslider from "@/views/pages/elements/rangeslider.vue";
import Rating from "@/views/pages/elements/rating.vue";
import Ribbon from "@/views/pages/elements/ribbon.vue";
import Scrollbar from "@/views/pages/elements/scrollbar.vue";
import Spinner from "@/views/pages/elements/spinner.vue";
import Stickynote from "@/views/pages/elements/stickynote.vue";
import Sweetalerts from "@/views/pages/elements/sweetalerts.vue";
import timeline from "@/views/pages/elements/timeline.vue";
import toastr from "@/views/pages/elements/toastr.vue";
import tooltip from "@/views/pages/elements/tooltip.vue";
import Chartapex from "@/views/pages/charts/apex/chart-apex.vue";
import Chartc3 from "@/views/pages/charts/c3/chart-c3.vue";
import chartflot from "@/views/pages/charts/flot/chart-flot.vue";
import chartmorris from "@/views/pages/charts/morris/chart-morris.vue";
import Iconfeather from "@/views/pages/icons/icon-feather.vue";
import Iconflag from "@/views/pages/icons/icon-flag.vue";
import Iconfontawesome from "@/views/pages/icons/icon-fontawesome.vue";
import Iconionic from "@/views/pages/icons/icon-ionic.vue";
import Iconpe7 from "@/views/pages/icons/icon-pe7.vue";
import Iconsimpleline from "@/views/pages/icons/icon-simpleline.vue";
import Iconthemify from "@/views/pages/icons/icon-themify.vue";
import Icontypicon from "@/views/pages/icons/icon-typicon.vue";
import Iconweather from "@/views/pages/icons/icon-weather.vue";
import iconmaterial from "@/views/pages/icons/icon-material.vue";
import Formbasicinputs from "@/views/pages/forms/form-basic-inputs.vue";
import Formfileupload from "@/views/pages/forms/form-fileupload.vue";
import Formhorizontal from "@/views/pages/forms/horizontal/form-horizontal.vue";
import Forminputgroups from "@/views/pages/forms/form-input-groups.vue";
import Formmask from "@/views/pages/forms/form-mask.vue";
import Formselect2 from "@/views/pages/forms/form-select2.vue";
import Formvalidation from "@/views/pages/forms/form-validation.vue";
import Formvertical from "@/views/pages/forms/form-vertical.vue";
import FormWizard from "@/views/pages/forms/form-wizard.vue";
import chartJS from "@/views/pages/charts/js/chart-js.vue";
import Datatable from "@/views/pages/table/data-tables.vue";
import Tablesbasic from "@/views/pages/table/tables-basic.vue";
import chat from "@/views/pages/application/chat.vue";
import Email from "@/views/pages/application/email.vue";
import customerreport from "@/views/pages/report/customerreport.vue";
import Inventoryreport from "@/views/pages/report/inventoryreport.vue";
import Invoicereport from "@/views/pages/report/invoicereport.vue";
import Purchaseorderreport from "@/views/pages/report/purchaseorderreport.vue";
import Purchasereport from "@/views/pages/report/purchasereport.vue";
import Salesreport from "@/views/pages/report/salesreport.vue";
import Supplierreport from "@/views/pages/report/supplierreport/supplierreport.vue";
import Newuser from "@/views/pages/users/newuser.vue";
import Newuseredit from "@/views/pages/users/newuseredit.vue";
import userlists from "@/views/pages/users/userlists.vue";
import useraccount from "@/views/pages/users/useraccount.vue";
import usertransactions from "@/views/pages/users/usertransactions.vue";
import currencysettings from "@/views/pages/settings/currencysetting/currencysettings.vue";
import createpermission from "@/views/pages/settings/createpermission.vue";
import Editpermission from "@/views/pages/settings/editpermission.vue";
import Emailsettings from "@/views/pages/settings/emailsettings.vue";
import Generalsettings from "@/views/pages/settings/generalsettings.vue";
import Permissions from "@/views/pages/settings/permission/permissions.vue";
import Paymentsettings from "@/views/pages/settings/paymentsettings.vue";
import Usersettings from "@/views/pages/settings/usersettings.vue";
import Taxrates from "@/views/pages/settings/taxrates.vue";
import Signin from "@/views/pages/auth/signin.vue";
import Signup from "@/views/pages/auth/sign-up-modified.vue";
import Forgetpassword from "@/views/pages/auth/forgetpassword.vue";
import Resetpassword from "@/views/pages/auth/resetpassword.vue";
import Pos from "@/views/pages/pos/pos.vue";
import AdminRole from "@/views/pages/admin/admin-role.vue";
import AddRole from "@/views/pages/admin/add-role.vue";
import RoleDetails from "@/views/pages/admin/role-details.vue";
import Admin from "@/views/pages/admin/admin.vue";
import EditAdmin from "@/views/pages/admin/edit-admin.vue";
import NewAdmin from "@/views/pages/admin/new-admin.vue";
import CrateReport from "@/views/pages/crate/report/crate-report.vue";
import ReceiptList from "@/views/pages/sales/receiptlist.vue";
import SplashVideo from "@/views/pages/adverts/mobile/splashvideo.vue";
import addintransit from "@/views/pages/product/intransit/addintransit.vue";
import transitlist from "@/views/pages/product/intransit/transitlist.vue";
import transitdetail from "@/views/pages/product/intransit/transit-detail.vue";
import cratetypelist from "@/views/pages/crate/cratetypelist.vue";
import archivedOrders from "@/views/pages/archive/orders.vue";
import paymentreport from "@/views/pages/report/paymentreport.vue";
import purchasereport from "@/views/pages/report/purchasereport.vue";
import VerifyEmail from "@/views/pages/auth/verifyemail.vue";
import EmailVerified from "@/views/pages/auth/emailverified.vue";
import VendorList from "@/views/pages/vendors/vendor-list.vue";
import PendingVendors from "@/views/pages/vendors/pending-vendors.vue";
import RejectedVendors from "@/views/pages/vendors/declined-vendors.vue";
import CouponsManage from "@/views/pages/promo/coupons.vue";
import ManageStocks from "@/views/pages/stock/manage-stocks.vue";
import StockTransfer from "@/views/pages/stock/stock-transfer.vue";
import StockAdjust from "@/views/pages/stock/stock-adjust.vue";
import limitincrease from "@/views/pages/users/limitincrease.vue";
import usersReport from "@/views/pages/report/users-report.vue";
import Importproducts from "@/views/pages/product/importproducts.vue";
import { isDevelopment } from "@/constants/env";
import ListNotification from "@/views/pages/push-notification/list-notification.vue";
import CreateNotification from "@/views/pages/push-notification/create-notification.vue";
import EditNotification from "@/views/pages/push-notification/edit-notification.vue";
import BarredUsers from "@/views/pages/users/barred-users.vue";
import Splashscreen from "@/views/pages/adverts/mobile/splashscreen.vue";

const templateRoutes = [
  {
    path: "/index-one",
    name: "index-one",
    component: IndexOne,
    meta: {
      requiresAuth: true,
      headerClass: "header header-one",
      headerthreeClass: "sidebar new-header sidebar-one",
      sidebarClass: "sidebar-one hide-sidebar",
    },
  },
  {
    path: "/index-two",
    name: "index-two",
    component: IndexTwo,
    meta: {
      requiresAuth: true,
      headerClass: "header header-two",
      divClass: "container",
    },
  },
  {
    path: "/index-three",
    name: "index-three",
    component: IndexThree,
    meta: {
      requiresAuth: true,
      headerClass: "header header-three",
      headerthreeClass: "sidebar side-three new-header",
      divClass: "container",
      sidebarClass: "sidebar-three hide-sidebar",
    },
  },
  {
    path: "/index-four",
    name: "index-four",
    component: IndexFour,
    meta: {
      requiresAuth: true,
      headerClass: "header header-four",
      hideClass: "v-cloak",
    },
  },

  {
    path: "/addpurchase",
    name: "addpurchase",
    component: Addpurchase,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editpurchase",
    name: "editpurchase",
    component: Editpurchase,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/importpurchase",
    name: "importpurchase",
    component: Importpurchase,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/purchaselist",
    name: "purchaselist",
    component: Purchaselist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/createexpense",
    name: "createexpense",
    component: createexpense,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editexpense",
    name: "editexpense",
    component: Editexpense,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/expensecategory",
    name: "expensecategory",
    component: Expensecategory,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/expenselist",
    name: "expenselist",
    component: Expenselist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/addquotation",
    name: "addquotation",
    component: Addquotation,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editquotation",
    name: "editquotation",
    component: Editquotation,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/quotationlist",
    name: "quotationlist",
    component: Quotationlist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/addtransfer",
    name: "addtransfer",
    component: Addtransfer,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/edittransfer",
    name: "edittransfer",
    component: Edittransfer,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/importtransfer",
    name: "importtransfer",
    component: Importtransfer,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/transferlist",
    name: "transferlist",
    component: transferlist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/createpurchasereturn",
    name: "createpurchasereturn",
    component: createpurchasereturn,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editpurchasereturn",
    name: "editpurchasereturn",
    component: Editpurchasereturn,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editsalesreturn",
    name: "editsalesreturn",
    component: Editsalesreturn,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/purchasereturnlist",
    name: "purchasereturnlist",
    component: Purchasereturnlist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/addcustomer",
    name: "addcustomer",
    component: Addcustomer,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/addstore",
    name: "addstore",
    component: Addstore,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/addsupplier",
    name: "addsupplier",
    component: Addsupplier,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editcustomer",
    name: "editcustomer",
    component: Editcustomer,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editsupplier",
    name: "editsupplier",
    component: Editsupplier,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/supplierlist",
    name: "supplierlist",
    component: Supplierlist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/userlist",
    name: "userlist",
    component: userlist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/manage-stock",
    name: "manage-stock",
    component: ManageStocks,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/stock-transfer",
    name: "stock-transfer",
    component: StockTransfer,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/stock-adjust",
    name: "stock-adjust",
    component: StockAdjust,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/customerlist",
    name: "customerlist",
    component: customerlist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editcountry",
    name: "editcountry",
    component: Editcountry,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editstate",
    name: "editstate",
    component: Editstate,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/newcountry",
    name: "newcountry",
    component: Newcountry,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/newstate",
    name: "newstate",
    component: Newstate,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/statelist",
    name: "statelist",
    component: Statelist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/storelist",
    name: "storelist",
    component: Storelist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/countrieslist",
    name: "countrieslist",
    component: Countrieslist,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/blankpage",
    name: "blankpage",
    component: Blankpage,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/components",
    name: "components",
    component: Component,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/error-500",
    name: "error-500",
    component: Error500,
  },
  {
    path: "/clipboard",
    name: "clipboard",
    component: clipboard,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/counter",
    name: "counter",
    component: counter,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/drag-drop",
    name: "drag-drop",
    component: Dragdrop,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/lightbox",
    name: "lightbox",
    component: Lightbox,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/notification",
    name: "notification",
    component: notification,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/barcode",
    name: "barcode",
    component: Barcode,
    meta: {
      access: "access-barcode",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/popover",
    name: "popover",
    component: Popover,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/rangeslider",
    name: "rangeslider",
    component: Rangeslider,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/rating",
    name: "rating",
    component: Rating,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/ribbon",
    name: "ribbon",
    component: Ribbon,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/scrollbar",
    name: "scrollbar",
    component: Scrollbar,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/spinner",
    name: "spinner",
    component: Spinner,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/stickynote",
    name: "stickynote",
    component: Stickynote,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/sweetalerts",
    name: "sweetalerts",
    component: Sweetalerts,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/timeline",
    name: "timeline",
    component: timeline,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/toastr",
    name: "toastr",
    component: toastr,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/tooltip",
    name: "tooltip",
    component: tooltip,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/chart-apex",
    name: "chart-apex",
    component: Chartapex,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/chart-c3",
    name: "chart-c3",
    component: Chartc3,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/chart-flot",
    name: "chart-flot",
    component: chartflot,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/chart-morris",
    name: "chart-morris",
    component: chartmorris,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-feather",
    name: "icon-feather",
    component: Iconfeather,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-flag",
    name: "icon-flag",
    component: Iconflag,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-fontawesome",
    name: "icon-fontawesome",
    component: Iconfontawesome,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-ionic",
    name: "icon-ionic",
    component: Iconionic,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-pe7",
    name: "icon-pe7",
    component: Iconpe7,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-simpleline",
    name: "icon-simpleline",
    component: Iconsimpleline,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-themify",
    name: "icon-themify",
    component: Iconthemify,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-typicon",
    name: "icon-typicon",
    component: Icontypicon,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-weather",
    name: "icon-weather",
    component: Iconweather,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/icon-material",
    name: "icon-material",
    component: iconmaterial,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/form-basic-inputs",
    name: "form-basic-inputs",
    component: Formbasicinputs,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/form-fileupload",
    name: "form-fileupload",
    component: Formfileupload,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/form-horizontal",
    name: "form-horizontal",
    component: Formhorizontal,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/form-input-groups",
    name: "form-input-groups",
    component: Forminputgroups,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/form-mask",
    name: "form-mask",
    component: Formmask,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/form-select2",
    name: "form-select2",
    component: Formselect2,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/form-validation",
    name: "form-validation",
    component: Formvalidation,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/form-vertical",
    name: "form-vertical",
    component: Formvertical,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/form-wizard",
    name: "form-wizard",
    component: FormWizard,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/chart-js",
    name: "chart-js",
    component: chartJS,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/data-tables",
    name: "data-tables",
    component: Datatable,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/tables-basic",
    name: "tables-basic",
    component: Tablesbasic,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/email",
    name: "email",
    component: Email,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/customerreport",
    name: "customerreport",
    component: customerreport,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/purchaseorderreport",
    name: "purchaseorderreport",
    component: Purchaseorderreport,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/salesreport",
    name: "salesreport",
    component: Salesreport,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/supplierreport",
    name: "supplierreport",
    component: Supplierreport,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/currencysettings",
    name: "currencysettings",
    component: currencysettings,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/createpermission",
    name: "createpermission",
    component: createpermission,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editpermission",
    name: "editpermission",
    component: Editpermission,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/emailsettings",
    name: "emailsettings",
    component: Emailsettings,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/generalsettings",
    name: "generalsettings",
    component: Generalsettings,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/permissions",
    name: "permissions",
    component: Permissions,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/taxrates",
    name: "taxrates",
    component: Taxrates,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/forgetpassword",
    name: "forgetpassword",
    component: Forgetpassword,
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: Resetpassword,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/pos",
    name: "pos",
    component: Pos,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
];
const routes = [
  {
    path: "/signin",
    name: "signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },
  {
    path: "/verifyemail",
    name: "verifyemail",
    component: VerifyEmail,
  },
  {
    path: "/emailverified",
    name: "emailverified",
    component: EmailVerified,
  },
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/activities",
    name: "activities",
    component: Activities,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/addbrand",
    name: "addbrand",
    component: Addbrand,
    meta: {
      access: "create-brand",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/addcategory",
    name: "addcategory",
    component: Addcategory,
    meta: {
      access: "create-category",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  /** CRATE TYPE */
  {
    path: "/cratetypes",
    name: "cratetypes",
    component: cratetypelist,
    meta: {
      access: "access-crate-types",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  /** PRODUCTS START */
  {
    path: "/add-intransit",
    name: "add-intransit",
    component: addintransit,
    meta: {
      access: "add-transit",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/transits/:id",
    name: "view-transit",
    component: transitdetail,
    meta: {
      access: "view-transits",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/transits",
    name: "transits",
    component: transitlist,
    meta: {
      access: "view-transits",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/addproduct",
    name: "addproduct",
    component: Addproduct,
    meta: {
      access: "create-product",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/brandlist",
    name: "brandlist",
    component: Brandlist,
    meta: {
      access: "retrieve-brands",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/categorylist",
    name: "categorylist",
    component: Categorylist,
    meta: {
      access: "retrieve-categories",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editbrand",
    name: "editbrand",
    component: Editbrand,
    meta: {
      access: "edit-brand",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editcategory/:id",
    name: "editcategory",
    component: Editcategory,
    meta: {
      access: "edit-editcategory",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editproduct/:id",
    name: "editproduct",
    component: Editproduct,
    meta: {
      access: "edit-product",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editsubcategory",
    name: "editsubcategory",
    component: Editsubcategory,
    meta: {
      access: "edit-subcategory",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/products",

    children: [
      {
        path: "",
        name: "productlist",
        component: Productlist,
        meta: {
          access: "access-active-products",
          requiresAccess: true,
          requiresAuth: true,
          headerClass: "header",
          sidebarClass: "",
        },
      },
      {
        path: "import",
        name: "importproducts",
        component: Importproducts,
        meta: {
          access: "import-product",
          requiresAccess: true,
          requiresAuth: true,
          headerClass: "header",
          sidebarClass: "",
        },
      },
      {
        path: ":id",
        name: "product-details",
        component: ProductDetails,
        meta: {
          access: "access-products",
          requiresAccess: true,
          requiresAuth: true,
          headerClass: "header",
          sidebarClass: "",
        },
      },
    ],
  },
  {
    path: "/pending-products",
    name: "pending-products",
    component: Productlist,
    meta: {
      access: "access-inactive-products",
      requiresAccess: true,
      requiresAuth: true,
      status: "inactive",
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/subaddcategory",
    name: "subaddcategory",
    component: Subaddcategory,
    meta: {
      access: "create-subcategory",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/subcategorylist",
    name: "subcategorylist",
    component: Subcategorylist,
    meta: {
      access: "retrieve-subcategories",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/add-order",
    name: "add-order",
    component: Addorder,
    meta: {
      access: "create-order",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/add-sales",
    name: "add-sales",
    component: Addsale,
    meta: {
      access: "create-sale.*",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/confirm-sales",
    name: "confirm-sales",
    component: ConfirmSale,
    meta: {
      access: "approve-sales",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/warehouse-sales",
    name: "warehouse-sales",
    component: WareHouseSale,
    meta: {
      access: "approved-sales",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/gate-sales",
    name: "gatesales",
    component: GateSale,
    meta: {
      access: "packed-sales",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/dispatched-sales",
    name: "dispatchedsales",
    component: DispatchedSale,
    meta: {
      access: "dispatched-sales",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/completed-sales",
    name: "completedsales",
    component: CompletedSale,
    meta: {
      access: "^completed-sales",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/edit-sales",
    name: "edit-sales",
    component: Editsales,
    meta: {
      access: "edit-sales",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editsalesreturns",
    name: "editsalesreturns",
    component: Editsalesreturns,
    meta: {
      access: "access-sales-return",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/orderlist",
    name: "orderlist",
    component: OrderList,
    meta: {
      access: "access-orders$",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/orders/:id",
    name: "order-detail",
    component: OrderDetail,
    meta: {
      access: "access-orders-details",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/sales/:id",
    name: "sales-details",
    component: OrderDetail,
    meta: {
      access: "access-orders-details",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/saleslist",
    name: "saleslist",
    component: Saleslist,
    meta: {
      access: "access-pending-sales",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/pending-saleslist",
    name: "pending-saleslist",
    component: Saleslist,
    meta: {
      access: "access-pending-sales",
      requiresAccess: true,
      requiresAuth: true,
      type: "Pending",
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/completed-saleslist",
    name: "completed-saleslist",
    component: Saleslist,
    meta: {
      access: "access-completed-sales",
      requiresAccess: true,
      requiresAuth: true,
      type: "Completed",
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/createsalesreturn",
    name: "createsalesreturn",
    component: createsalesreturn,
    meta: {
      access: "access-sales-return",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/salesreturnlist",
    name: "salesreturnlist",
    component: Salesreturnlist,
    meta: {
      access: "access-sales-return",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/salesreturnlists",
    name: "salesreturnlists",
    component: Salesreturnlists,
    meta: {
      access: "access-sales-return",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/adduser",
    name: "adduser",
    component: Adduser,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/editstore",
    name: "editstore",
    component: Editstore,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/coupons",
    name: "coupons",
    component: CouponsManage,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      requiresAccess: false,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/chat",
    name: "chat",
    component: chat,
    meta: {
      requiresAccess: false,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/paymentreport",
    name: "paymentreport",
    component: paymentreport,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/purchasereport",
    name: "purchasereport",
    component: purchasereport,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/inventoryreport",
    name: "inventoryreport",
    component: Inventoryreport,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/invoicereport",
    name: "invoicereport",
    component: Invoicereport,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/purchasereport",
    name: "purchasereport",
    component: Purchasereport,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },

  {
    path: "/newuser",
    name: "newuser",
    component: Newuser,
    meta: {
      access: "create-user",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/edit-user/:id",
    name: "edituser",
    component: Edituser,
    meta: {
      access: "update-user",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/barredusers",
    name: "barredusers",
    component: BarredUsers,
    meta: {
      access: "view-barred-users",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/userlists",
    name: "userlists",
    component: userlists,
    meta: {
      access: "view-users",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/users-account",
    name: "users-account",
    component: useraccount,
    meta: {
      access: "view-users-account",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/users-account/:accountNumber",
    name: "user-transactions",
    component: usertransactions,
    meta: {
      access: "view-user-transactions",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/users/:account_type",
    name: "users",
    component: userlists,
    meta: {
      access: "view-users",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/user-settings",
    name: "usersettings",
    component: Usersettings,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/paymentsettings",
    name: "paymentsettings",
    component: Paymentsettings,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  // ADMIN ROUTES
  {
    path: "/admin-role",
    name: "admin-role",
    component: AdminRole,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  {
    path: "/admin-role/:id",
    name: "admin-role-detail",
    component: RoleDetails,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  {
    path: "/newrole",
    name: "newrole",
    component: AddRole,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: {
      // access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  {
    path: "/edit-admin/:id",
    name: "edit-admin",
    component: EditAdmin,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  {
    path: "/newadmin",
    name: "newadmin",
    component: NewAdmin,
    meta: {
      // access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  //CRATE

  {
    path: "/crate-report/:type?",
    name: "crate-report",
    component: CrateReport,
    meta: {
      access: "access-crate-report",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  {
    path: "/vendors-list",
    name: "vendors-list",
    component: VendorList,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  {
    path: "/pending-vendors",
    name: "pending-vendors",
    component: PendingVendors,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  {
    path: "/rejected-vendors",
    name: "rejected-vendors",
    component: RejectedVendors,
    meta: {
      access: "access-all",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  // Receipts
  {
    path: "/receipts",
    name: "receipts",
    component: ReceiptList,
    meta: {
      access: "access-receipts",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },

  /**** ADVERTS */
  {
    path: "/adverts/mobile/video",
    name: "mobile-splash-video",
    component: SplashVideo,
    meta: {
      access: "access-mobile-splash-video",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  {
    path: "/adverts/mobile/splash-screen",
    name: "mobile-splash-screen",
    component: Splashscreen,
    meta: {
      access: "access-mobile-splash-screen",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
    },
  },
  // ARCHIVE
  {
    path: "/archive/orders",
    name: "archiveorders",
    component: archivedOrders,
    meta: {
      access: "access-archived-orders$",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/limit-increase/pending",
    name: "pending-limit-increase",
    component: limitincrease,
    meta: {
      status: "pending",
      access: "access-pending-credit-limit-increase",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/limit-increase/processed",
    name: "processed-limit-increase",
    component: limitincrease,
    meta: {
      status: "processed",
      access: "access-processed-credit-limit-increase",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "error-404",
    component: Error404,
  },

  {
    path: "/users-report",
    name: "users-report",
    component: usersReport,
    meta: {
      access: "access-users-report",
      requiresAccess: true,
      requiresAuth: true,
      headerClass: "header",
      sidebarClass: "",
    },
  },
  {
    path: "/notification",
    children: [
      {
        path: "",
        name: "",
        component: ListNotification,
        meta: {
          access: "list-notifications",
          requiresAccess: true,
          requiresAuth: true,
          headerClass: "header",
          sidebarClass: "",
        },
      },
      {
        path: "create",
        name: "create_notification",
        component: CreateNotification,
        meta: {
          access: "create-notification",
          requiresAccess: true,
          requiresAuth: true,
          headerClass: "header",
          sidebarClass: "",
        },
      },
      {
        path: "edit/:id",
        name: "edit_notification",
        component: EditNotification,
        meta: {
          access: "edit-notification",
          requiresAccess: true,
          requiresAuth: true,
          headerClass: "header",
          sidebarClass: "",
        },
      },
    ],
  },
  {
    path: "/hidden",
    name: "Templates",
    children: isDevelopment ? templateRoutes : [],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

/**
 *
 * @param {RouteLocationNormalized} route
 */
const middleware = (route) => {
  let result = { toLogin: false, navigate: true, accessDenied: false };
  if (route.meta.requiresAuth) {
    if (isAuth()) {
      result.navigate = true;
      if (
        route.meta.requiresAccess &&
        !store.getters.hasAccess(route.meta.access)
      ) {
        result.accessDenied = true;
        result.navigate = false;
      }
    } else {
      result.toLogin = true;
      result.navigate = false;
    }
  }
  return result;
};

router.beforeEach((to, from, next) => {
  const { toLogin, navigate, accessDenied } = middleware(to);
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: "smooth" });
  if (navigate) {
    next();
  } else if (toLogin) {
    next("/signin");
  } else if (accessDenied) {
    Swal.fire("Access Denied", "", "error");
    next(from.path);
  }
});

export { router };
